import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import axios from 'axios';
import JSZip from 'jszip';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { DataLayer } from '../measurement';
import { assumeTextureType } from './display';
import { updateProjectAssetCounter } from './updateData';

/**
 * Is the project downloadable or not
 * @returns {Boolean} Whether the project is downloadable [true] or not [false, default]
 */
export function isProjectDownloadable(projectData: PublicAsset | null): boolean {
	if (projectData) {
		// Check if the project has a zip file
		if (projectData?.files && projectData.files.some((file) => file?.downloadUrl)) {
			// Check if at least one file is downloadable
			return true;
		}

		if (projectData?.path) {
			return true;
		}
	}

	return false;
}

/**
 * Open a project in-app and continue editing/working on it
 * @appOnly
 */
async function openContinueProject(projectData: Partial<PublicAsset>) {
	if (window.vuplex) {
		// In the path, if it ends with a file (any extension), remove it
		let { path } = projectData;
		const pathFileExtension = path?.split('.');
		if (pathFileExtension && !isEmpty(pathFileExtension)) {
			// Remove the file at the end of the path
			const pathDirectories = path?.split('/');

			if (pathDirectories && !isEmpty(pathDirectories)) {
				pathDirectories.pop();
				path = pathDirectories.join('/');
			}
		}

		// Track the event
		DataLayer.triggerMeasurementEvent('assetEvent', {
			event_name: 'project_open_continue',
			project_id: projectData?.id ?? '',
		});

		const vuplexMessage = {
			type: 'load_project',
			data: {
				id: projectData.id,
				path,
				projectName: projectData?.title ?? 'Untitled Project',
			},
		};

		window.vuplex?.postMessage(vuplexMessage);
	} else {
		// Track the error
		DataLayer.triggerErrorEvent('project_open_continue', 'vuplex_not_found', {
			project_id: projectData?.id ?? '',
		});
		console.error(`Vuplex not found. Error continuing project: "${projectData?.title ?? 'Untitled Project'}"`);
		toast.error(`Vuplex not found. Error continuing project: "${projectData?.title ?? 'Untitled Project'}"`);
	}
}

export const activeDownloadingProjects: any[] = [];
/**
 * Download all related files of a project
 * @returns {Boolean} Whether the project files were downloaded [true] or not [false, default] (or null for in-app)
 * @async
 */
export async function downloadProjectFiles(
	projectData: PublicAsset | null | undefined,
	isInApp: boolean,
): Promise<boolean | null> {
	let downloadStatus = false;
	const inApp = Boolean(isInApp || window?.vuplex || store.get(inAppBrowserAtom));

	// Only proceed if project data is available
	if (projectData) {
		if (!activeDownloadingProjects.includes(projectData?.id)) {
			if (typeof projectData?.id === 'number') {
				// Update download counter:
				updateProjectAssetCounter(projectData.id, store.get(inAppBrowserAtom) ? 'remixes' : 'downloads');
			}

			activeDownloadingProjects.push(projectData?.id);

			if (inApp) {
				if (projectData?.path) {
					openContinueProject(projectData);
				} else {
					/** Project files data: name and URL only */
					const projectFiles = projectData?.files?.map((file) => ({
						name: file.name,
						url: file.downloadUrl,
					}));

					if (projectFiles) {
						// If category is "material", go through project files (if jpg, jpeg, png, bmp, or tiff), and assume texture type
						/** Accepted texture file formats */
						const acceptedImageFormats = ['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'exr'];
						const thumbnailFormats = ['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'webp', 'svg', 'exr'];
						projectFiles.forEach(
							(file: { name: string; assumedType?: string | null; url?: string | URL }) => {
								/** Project file name cleaned, trimmed and lowercase */
								const fileName = file?.name?.trim()?.toLowerCase();
								const fileNameExtension = fileName?.split('.').pop()?.toLowerCase();
								file.assumedType = '';

								if (fileName && fileNameExtension) {
									if (
										fileName?.includes('thumbnail') &&
										!isEmpty(fileNameExtension) &&
										thumbnailFormats.includes(fileNameExtension)
									) {
										if (fileNameExtension === 'gif') {
											file.assumedType = 'thumbnail_gif';
										} else {
											file.assumedType = 'thumbnail';
										}
									} else if (
										fileName === 'no-image-placeholder.svg' ||
										file?.url ===
											'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'
									) {
										// ! @AlexJSully: This is the old test thumbnail, it should not appear anymore but in case it does, we'll assume any file with this name or URL is a thumbnail. Once ensured that this is no longer the case, remove this if statement
										file.assumedType = 'thumbnail';
									} else if (projectData?.category?.includes('material')) {
										// If GLB or glTF file, remove it from the list as we won't be downloading it
										if (['glb', 'gltf'].includes(fileNameExtension) && inApp) {
											file.assumedType = projectData.category;
										} else if (
											acceptedImageFormats.includes(fileNameExtension) &&
											assumeTextureType(file.name, projectData?.title || 'Untitled Project')
										) {
											file.assumedType = assumeTextureType(file.name);
										}
									} else if (
										projectData?.category &&
										['rig', 'animation'].includes(projectData.category)
									) {
										if (['glb', 'gltf'].includes(fileNameExtension)) {
											file.assumedType = projectData.category;
										}
									} else if (['glb', 'gltf'].includes(fileNameExtension)) {
										// Model formats can be glb or gltf
										file.assumedType = 'model';
									}
								}
							},
						);
					}

					/** Data that will be sent to the vuplex app */
					const vuplexMessageData = {
						type: 'import',
						data: {
							id: `${projectData.id}`,
							category: projectData.category,
							files: projectFiles,
							title: projectData?.title || 'Untitled Project',
						},
					};

					if (inApp) {
						if (window?.vuplex) {
							window.vuplex?.postMessage(vuplexMessageData);

							toast.info(`Importing "${projectData?.title || 'Untitled Project'}"`);

							// Send event to Google Analytics
							/** Event name to send to Google Analytics */
							let eventName = 'project_import_file';
							if (projectData.category === 'model' && !projectData.tags?.includes('stamp')) {
								eventName = 'is_new_project';
							}

							DataLayer.triggerMeasurementEvent('assetEvent', {
								event_name: eventName,
								project_id: projectData?.id ?? '',
								project_file_count: projectData?.files?.length ?? 0,
							});
						} else {
							toast.error(
								`Vuplex not found. Error importing: "${projectData?.title || 'Untitled Project'}"`,
							);
							throw new Error(
								`Vuplex not found. Error importing: "${projectData?.title || 'Untitled Project'}"`,
							);
						}

						return null;
					}
				}
			}

			/** Project title to display in toast */
			let displayTitle = projectData?.title || 'Untitled Project';
			if (displayTitle.length > 32) {
				displayTitle = `${displayTitle.substring(0, 32)}...`;
			}

			// Begin downloading files
			/** Toast loading ID */
			const toastID = toast.loading(`Downloading "${displayTitle}"`, {
				closeOnClick: true,
				draggable: true,
			});
			/** Maximum number of steps for toast loading */
			const toastSteps = Number(projectData?.path ? 1 : (projectData?.files?.length ?? 0) + 3);
			/** Current step in toast loading */
			let currentStep = 1;
			// Set-up toast loading
			toast.update(toastID, {
				render: `Downloading "${displayTitle}": ${Math.floor(
					(currentStep / toastSteps) * 100,
				)}%: Initializing download`,
				isLoading: true,
				autoClose: false,
			});

			if (projectData?.files) {
				let projectTitle = projectData?.title || 'Untitled Project';
				// If the project title ends with a file name, remove it
				if (projectTitle?.includes('.')) {
					const projectTitleSplit = projectTitle.split('.');
					projectTitleSplit.pop();
					projectTitle = projectTitleSplit.join('.');
				}

				/** JSZip */
				const zip = await new JSZip();

				// Loop through the project's files
				if (!isEmpty(projectData.files)) {
					// eslint-disable-next-line no-restricted-syntax
					for (const i in projectData.files) {
						if (projectData.files[i]) {
							/** Individual file per project */
							const file = projectData.files[i];

							// If file is downloadable
							if (file?.downloadUrl) {
								try {
									// eslint-disable-next-line no-await-in-loop
									const response = await axios.get(file.downloadUrl, {
										responseType: 'arraybuffer',
										headers: {
											'Content-Type': 'application/octet-stream',
										},
									});

									/** Convert response to be readable within adm-zip */
									const buffer = Buffer.from(response.data, 'binary');

									// Add file to zip
									zip.file(file.name, buffer);

									// Update toast loading
									currentStep += 1;
									toast.update(toastID, {
										render: `Downloading "${displayTitle}": ${Math.floor(
											(currentStep / toastSteps) * 100,
										)}%: Processed ${projectData.files[i].name}`,
										isLoading: true,
										autoClose: false,
									});

									// If last entry, download zip file
									if (Number(i) === Number(projectData.files.length - 1)) {
										// Update toast loading
										currentStep += 1;
										toast.update(toastID, {
											render: `Downloading "${displayTitle}": ${Math.floor(
												(currentStep / toastSteps) * 100,
											)}%: Zipping files`,
											isLoading: true,
											autoClose: false,
										});

										// Zip and download project
										// eslint-disable-next-line no-await-in-loop
										await zip
											.generateAsync({ type: 'blob' })
											// eslint-disable-next-line @typescript-eslint/no-loop-func, no-loop-func
											.then(async (content) => {
												/** Downloadable link */
												const url = window.URL.createObjectURL(content);
												// Create a link to download the zip then delete it
												/** Action element to download zip file */
												const link = document?.createElement('a');
												// Set the link's href to the URL
												link.href = url;
												// Set the link's download attribute to the file name
												link.setAttribute(
													'download',
													`${projectTitle || 'Untitled Project'}.zip`,
												);
												// Append the link to the document
												document.body.appendChild(link);
												// Click the link
												link.click();
												// Delete the link
												document.body.removeChild(link);

												// Update toast loading (success)
												toast.update(toastID, {
													autoClose: 5000,
													isLoading: false,
													render: `Download complete for "${displayTitle}"`,
													type: 'success',
												});

												// Track the event
												DataLayer.triggerMeasurementEvent('assetEvent', {
													event_name: 'project_download_file',
													project_id: projectData?.id ?? '',
													project_file_count: projectData?.files?.length ?? 0,
												});

												downloadStatus = true;
											})
											// eslint-disable-next-line @typescript-eslint/no-loop-func, no-loop-func
											.catch((err) => {
												console.error(err);

												DataLayer.triggerErrorEvent('project_download_file', undefined, {
													project_id: projectData?.id ?? '',
													project_file_count: projectData?.files?.length ?? 0,
												});

												// Update toast loading (error)
												toast.update(toastID, {
													render: `Error downloading files for "${displayTitle}"`,
													type: 'error',
													isLoading: false,
													autoClose: 120000,
												});

												downloadStatus = true;
											});
									}
								} catch (error) {
									console.error(error);

									downloadStatus = true;
								}
							}
						}
					}
				}
			} else if (projectData?.path) {
				toast.update(toastID, {
					autoClose: 5000,
					isLoading: false,
					render: `Opened project "${displayTitle}"`,
					type: 'success',
				});

				// Track the event
				DataLayer.triggerMeasurementEvent('assetEvent', {
					event_name: 'project_open_path',
					project_id: projectData?.id ?? '',
				});
			}

			downloadStatus = true;

			// Complete download, remove projectId from array
			if (activeDownloadingProjects.includes(projectData?.id)) {
				activeDownloadingProjects.splice(activeDownloadingProjects.indexOf(projectData?.id), 1);
			}
		}
	} else {
		console.error(`Project data is not available`);
		toast.error('Unable to find downloadable data for project!', {
			position: 'bottom-right',
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
		});

		DataLayer.triggerErrorEvent('project_download_file', 'no_project_data');

		downloadStatus = true;
	}

	return downloadStatus;
}
