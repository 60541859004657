import { type PublicAsset } from '@mpx-sdk/types';
import { validateBytes } from 'gltf-validator';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

/** All already retrieved 3D models from the web to prevent too many network requests */
const cachedModel = {};

/**
 * Get 3D model details
 * @returns Information about the 3D model including the number of vertices, triangles, and materials
 */
export async function get3DModelData(
	/** The URL of the 3D model */
	src: string,
	/** The project data being processed */
	data?: {
		/** The project data */
		projectData?: PublicAsset;
		/** Type being processed */
		type?: 'online' | 'octet-stream';
		/** The file data */
		fileData?: any;
	},
): Promise<any> {
	const { projectData, type, fileData } = data ?? {};
	/** The result of the 3D model data, including vertices count */
	let result: any = null;

	// If no source or project data is provided, return
	if (!src) {
		return;
	}

	// If the model is already cached, return it
	if (type === 'online') {
		if (projectData && cachedModel[projectData?.id]) {
			return cachedModel[projectData?.id];
		}

		try {
			/** Fetch the file details */
			const response = await fetch(src);
			/** Convert the response to an array buffer */
			const buffer = await response.arrayBuffer();

			/** Validate the file */
			result = await validateBytes(new Uint8Array(buffer));
			// Cache the model
			if (type === 'online' && projectData && cachedModel[projectData?.id]) {
				cachedModel[projectData?.id] = result;
			}
		} catch (error) {
			console.error(error);
		}
	} else if (type === 'octet-stream') {
		/** base64 data, remove the data:image/png;base64, */
		const base64Data = src.split(',')[1];
		/** Decode the base64 data */
		const decodedString = atob(base64Data);
		/** Create a new Uint8Array */
		const buffer = new Uint8Array(decodedString.length);
		// Fill the buffer with the decoded string
		for (let i = 0; i < decodedString.length; i += 1) {
			buffer[i] = decodedString.charCodeAt(i);
		}

		/** File extension if applicable */
		const fileExtension = fileData?.file?.name?.split('.').pop()?.toLowerCase();

		if (['fbx', 'obj', 'stl'].includes(fileExtension)) {
			const blob = new Blob([buffer], { type: 'application/octet-stream' });
			const objectUrl = URL.createObjectURL(blob);

			let loader;
			if (fileExtension === 'fbx') {
				loader = new FBXLoader();
			} else if (fileExtension === 'obj') {
				loader = new OBJLoader();
			} else if (fileExtension === 'stl') {
				loader = new STLLoader();
			}

			/** The vertices of the model */
			const vertices: any[] = [];
			/** The number of vertices in the model */
			let vertexCount = 0;

			const object: any = await new Promise((resolve, reject) => {
				loader.load(objectUrl, resolve, undefined, reject);
			});

			if (object) {
				if (fileExtension === 'stl') {
					vertexCount = object.attributes.position.count;
				} else {
					object.traverse((child) => {
						if (child instanceof THREE.Mesh) {
							const { geometry } = child;
							const positionAttribute = geometry.attributes.position;
							const positionArray = positionAttribute.array;
							for (let i = 0; i < positionArray.length; i += 3) {
								const x = positionArray[i];
								const y = positionArray[i + 1];
								const z = positionArray[i + 2];
								vertices.push(new THREE.Vector3(x, y, z));
							}
						}
					});

					vertexCount = vertices.length;
				}
			}

			result = {
				info: {
					totalVertexCount: vertexCount,
				},
				metadata: {
					...object,
				},
			};
		} else if (['glb', 'gltf'].includes(fileExtension)) {
			result = await validateBytes(buffer);
		}
	}

	if (result) {
		result.id = projectData?.id;
	}

	return result;
}
