import { accessTokenProvider } from '@mpx-sdk/api-client';
import Env from '@mpx-sdk/shared/configs/env';
import axios, { type Method, type RawAxiosRequestHeaders } from 'axios';

export const request = async (
	url: string,
	method: Method,
	data?: Record<string, unknown> | FormData | string,
	headers: RawAxiosRequestHeaders = {},
) => {
	let dataOrParam: { data?: any; params?: any } = { data };

	if (method.toLowerCase() === 'get') {
		dataOrParam = { params: data };
	}

	return axios({
		url: Env.MPS_API_HOST + url,
		method,
		...dataOrParam,
		headers: {
			...headers,
			Authorization: headers.Authorization ?? `Bearer ${await accessTokenProvider.getAccessToken()}`,
		},
	});
};
