import BaseService from '@api-client/services/base.service';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { type AssetSlots, type PublicUserProfile, type UserBalance, type UserProfile } from '@mpx-sdk/types';

class UserService extends BaseService {
	async updateMyAvatar(formData: FormData) {
		return this.multipartPost(urls.api.account.updateAvatar, formData);
	}

	async getMyProfile() {
		return this.get<UserProfile>(urls.api.account.getMyProfile);
	}

	async updateMyProfile(data: any) {
		return this.patch(urls.api.account.updateProfile, data);
	}

	async updateSocials(data: any) {
		return this.patch(urls.api.account.updateSocials, data);
	}

	async deleteProfile(data: any) {
		return this.delete(urls.api.account.deleteProfile, data);
	}

	async getTopRemixers(howMany) {
		return this.get<PublicUserProfile>(urls.api.users.getTopRemixers(howMany));
	}

	async getMyBookmarks() {
		return this.get<any>(urls.api.account.getMyBookmarks);
	}

	async getUserByUsername(howMany) {
		return this.get<UserProfile>(urls.api.users.getUserByUsername(howMany));
	}

	/** Get user details by user id, username, or email */
	async getUserDetails(userId: string | number): Promise<any | null> {
		return this.get<any>(urls.api.users.getUserDetails(userId));
	}

	async getMyBalance(): Promise<UserBalance> {
		return this.get(urls.api.account.getMyBalance);
	}

	async getMySlots(): Promise<AssetSlots | null> {
		const data = await this.get(urls.api.account.getMySlots);

		if (data) {
			const slots = data;

			// Convert all values to numbers or 0
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(slots)) {
				slots[key] = Number(value) || 0;
			}

			return slots;
		}

		return null;
	}

	/** Add slots to a user */
	async addUserSlots(
		userId: number,
		slotType: 'project' | 'import' | 'export',
		slotsToAdd: number,
	): Promise<AssetSlots | null> {
		return this.post(urls.api.users.addUserSlots(userId), {
			slotType,
			slotsToAdd,
		});
	}

	async updateOtherUsers(userId: number, data: any) {
		return this.patch(urls.api.users.admin.updateOtherUsers(userId), data);
	}

	async updateSelfRoles(data: any) {
		return this.post(urls.api.users.updateSelfRoles, data);
	}

	async resendEmailVerification(userId: string) {
		return this.post(urls.api.account.resendEmailVerification, { userId });
	}
}

const userService = new UserService();

export default userService;
