class AccessTokenProvider {
	extractionFunction: (() => string) | null = null;

	async getAccessToken() {
		return this.extractionFunction?.();
	}

	extractJwtFrom(func: () => any): void {
		this.extractionFunction = func;
	}
}

export const accessTokenProvider = new AccessTokenProvider();
