import { urls } from '@mpx-sdk/shared/configs/urls';
import { type TeamsResponse } from '@mpx-sdk/types';
import { concat } from 'lodash';
import BaseService from './base.service';

class TeamService extends BaseService {
	public async inviteTeamMember(id: string, email: string) {
		return this.post(urls.api.team.inviteMember(id), {
			email,
			role: 'user',
		});
	}

	public async removeMember(teamId: string, memberId: string) {
		return this.delete(urls.api.team.removeMember(teamId, memberId));
	}

	public async acceptMemberInvite(token: string) {
		return this.post(urls.api.team.acceptInvitation(token));
	}

	public async getMyTeams() {
		const teams = await this.get<TeamsResponse>(urls.api.team.getMyTeams);
		return concat(teams?.ownerTeams, teams?.otherTeams);
	}
}

const teamService = new TeamService();

export default teamService;
