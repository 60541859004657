import { assetsService } from '@api-client/index';
import projectService from '@api-client/services/assets.service';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { type PublicAsset } from '@mpx-sdk/types';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { request } from '../api';
import { sanitizeString } from '../input';
import { DataLayer } from '../measurement';

/** Which projects have been viewed this session */
const projectIdsViewed: Array<number | string> = [];
export async function incrementViewCount(projectId: number | string) {
	// Check if user has already liked (or unliked) the project this session
	if (!projectIdsViewed.includes(projectId)) {
		// Add project ID to list of liked project IDs
		projectIdsViewed.push(projectId);

		// Increase/increment the view count
		return request(`/project/updateProjectViews/${projectId}`, 'POST')
			.then((data) => data.data)
			.catch((err) => {
				console.error(err);
			});
	}

	return null;
}

/**
 * Increase/increment the view count of a project
 * @param {Number} projectId Project ID to increase the view count
 * @returns {Object} Updated project data
 */
export async function addViewCount(projectId: number | string) {
	// Debounced version of incrementViewCount
	const debouncedAddViewCount = debounce(async (projectId) => {
		await incrementViewCount(projectId);
	}, 10000);

	return debouncedAddViewCount(projectId);
}

/**
 * Toggle whether a project is liked or not
 * @param {Number} projectId Project ID to toggle
 * @returns {Object} Whether the project has been liked [true] or not [false] - {liked: Boolean}
 */
export const toggleLikeProject = debounce(async (projectId: number, bookmark: boolean): Promise<object> => {
	try {
		console.log('bookmark', bookmark);
		// Update view count
		await addViewCount(projectId);
		const data = bookmark
			? await assetsService.bookmarkProject(projectId)
			: await assetsService.unbookmarkProject(projectId);
		// Add project ID to list of liked project IDs
		if (data) {
			toast.success(data?.bookmarked ? `Bookmarked project!` : `Unbookmarked project!`);

			// Track the event
			const trackEventData = {
				event_name: `project_bookmark`,
				projectId,
				bookmarked: data?.bookmarked,
			};
			DataLayer.triggerMeasurementEvent('assetEvent', trackEventData);

			return data;
		}

		toast.error(`Failed to bookmark project ${projectId}: ${data?.message}`);
		console.error(`Failed to bookmark project ${projectId}: `, { data });
	} catch (err) {
		console.error(err);
		toast.error(`Failed to bookmark project ${projectId}: ${err.message}`);
	}
}, 1000);

/**
 * Update the project's meta-data with new inputted values
 * @param {Object} projectData Current project data
 * @example <caption>This function requires existing/current project data for fallback/fail-safe values</caption>
 * updateProjectData({id: 1, title: 'Test', description: ''})
 * // returns null (does not return anything, instead just updates the project's meta-data)
 * @async
 */
export async function updateProjectData(projectData?: PublicAsset): Promise<PublicAsset | null> {
	if (projectData?.id) {
		// Get new inputted data, if not use old/existing data
		/** Title */
		const title = sanitizeString(
			(
				document?.querySelector(
					`[data-modal-project-id="modal-project-${projectData?.id}"][data-modal-entry-type="title"] textarea`,
				) as HTMLInputElement
			)?.value ||
				projectData?.title ||
				'Untitled Project',
		)?.substring(0, 64);

		/** Description */
		const description = sanitizeString(
			(
				document?.querySelector(
					`[data-modal-project-id="modal-project-${projectData?.id}"][data-modal-entry-type="description"] textarea`,
				) as HTMLInputElement
			)?.value ||
				projectData?.description ||
				'',
		)?.substring(0, 512);

		// Update metadata:
		if (title || description) {
			const newData = await projectService.updatePublicProject(projectData.id, {
				title,
				description,
			});

			if (newData?.data) {
				return newData.data;
			}
		}

		projectData.title = title || 'Untitled Project';
		projectData.description = description;

		return {
			...projectData,
			title: title || 'Untitled Project',
			description,
		};
	}

	return null;
}

/**
 * Update a project's download counter
 */
export async function updateProjectAssetCounter(projectId: number, type: 'downloads' | 'remixes' = 'downloads') {
	// Update view count
	addViewCount(projectId);

	return request(urls.api.assets.public.updateProjectAssetCounter(projectId, type), 'POST');
}

export async function updateUserProjectSlots(count) {
	if (count < 0 || Number.isNaN(Number(count))) {
		count = 0;
	}

	return request(`/me/slots/project-count/${count}`, 'patch');
}

/** Which projects have already been shared per session */
const shareIdsAlready: Array<number> = [];
/**
 * Update a project's share counter
 * @param {Number} projectId Project ID to update
 * @returns {Object} Updated project data
 */
export async function updateProjectShareCounter(projectId: number): Promise<object | null> {
	// If project not already shared, then update share counter
	if (projectId && !shareIdsAlready.includes(projectId)) {
		shareIdsAlready.push(projectId);

		// Update view count
		await addViewCount(projectId);

		// Update share counter
		return request(`/project/updateProjectShareCounter/${projectId}`, 'POST')
			.then((data) => data.data)
			.catch((err) => {
				console.error(err);
			});
	}

	return null;
}

/**
 * Update a project's tags data
 * @returns {Object} Updated project data
 * @example <caption>Use this project to add or remove a tag from a project (if already has tag, will remove it, else add it)</caption>
 * updateProjectTags(projectData, 'newTag')
 * // returns {tags: ['otherTag', 'newTag'], fuzzyTag: 'newTag'}
 * @async @exports
 * @see {@link sanitizeString}
 */
export async function updateProjectTags(
	projectData: PublicAsset | null,
	newTag: string,
	updateFunction?: (tags: string[]) => void,
): Promise<object | null> {
	/** Sanitized tag */
	let tag = sanitizeString(newTag)?.substring(0, 140)?.trim();
	// If first character is a #, remove it
	if (tag?.startsWith('#')) {
		tag = tag.substring(1);
	}

	// If project data exist and tag is valid
	if (projectData?.id && tag?.length > 2) {
		// Update project tags
		return request(`/project/updateProjectTags/${projectData.id}/${tag}`, 'POST')
			.then(async (data) => {
				if (data.data?.tags?.includes(tag)) {
					toast.success(`Updated "${projectData?.title}" with tag #${tag}`);
				} else {
					toast.success(`Removed #${tag} from "${projectData?.title}"`);
				}

				// Update project data
				if (data.data?.tags) {
					projectData.tags = data.data?.tags;
				} else {
					projectData.tags = [];
				}

				updateFunction?.(data.data?.tags);

				return data.data;
			})
			.catch((err) => {
				toast.error(`Unable to update project tags for "${projectData?.title}"`);

				console.error(err);
			});
	}

	toast.error(`Unable to update project tags for "${projectData?.title}"`);

	console.error(`Unable to update project tags for "${projectData?.title}": ${newTag}`);

	return null;
}
