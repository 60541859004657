import { accessTokenProvider } from '@api-client/services/access-token.provider';
import { CookieNames } from '@mpx-sdk/shared/configs/cookies';
import Env from '@mpx-sdk/shared/configs/env';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { isBrowser } from '@mpx-sdk/shared/utils';
import { type Method } from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import moment from 'moment';

abstract class BaseService {
	// eslint-disable-next-line class-methods-use-this
	protected async request<T>(
		url: string,
		method: Method,
		data?: Record<string, unknown> | FormData,
		headers: HeadersInit = {},
		/** Internal use, it will be true once refresh token attempt is made, so  it doesn't infinitely call */
		refreshedToken = false,
	) {
		url = ['http://', 'https://'].some((prefix) => url.startsWith(prefix)) ? url : urls.api.base + url;

		const headersRecord = headers as Record<string, string>;
		const accessToken = headersRecord.Authorization ?? `Bearer ${await accessTokenProvider.getAccessToken()}`;

		const fetchOptions: RequestInit = {
			method,
			headers: {
				...headers,
				Authorization: accessToken,
			},
		};

		if (method.toLowerCase() === 'get') {
			// For GET requests, add data as URL parameters
			const searchParams = new URLSearchParams(data as Record<string, string>);
			url += `?${searchParams.toString()}`;
		} else {
			// For other methods (POST, etc.), set the request body
			fetchOptions.body = JSON.stringify(data);

			if (!fetchOptions.headers) {
				fetchOptions.headers = {};
			}
			fetchOptions.headers['Content-Type'] = 'application/json';
		}

		const response = await fetch(url, fetchOptions);
		if (!response.ok) {
			if (response.status === 401 && isBrowser() && !refreshedToken) {
				const refreshToken = getCookie(CookieNames.REFRESH_TOKEN);

				if (refreshToken) {
					try {
						const res = await fetch(`${Env.AUTH0.DOMAIN}/oauth/token`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
							body: new URLSearchParams({
								grant_type: 'refresh_token',
								client_id: Env.AUTH0.IN_APP_CLIENT_ID || '',
								refresh_token: refreshToken || '',
							}),
						});

						const data = await res.json();

						if (!res.ok) {
							console.error('Error refreshing token', JSON.stringify(data));
							deleteCookie(CookieNames.REFRESH_TOKEN);
							deleteCookie(CookieNames.JWT);
							return;
						}

						setCookie(CookieNames.REFRESH_TOKEN, data.refresh_token, {
							expires: moment().add(30, 'days').toDate(),
						});
						setCookie(CookieNames.JWT, data.access_token, {
							expires: moment().add(data.expires_in, 'seconds').toDate(),
						});

						return this.request<T>(url, method, data, headers, true);
					} catch (err) {
						console.error('Error refreshing token', err);
						deleteCookie(CookieNames.REFRESH_TOKEN);
						deleteCookie(CookieNames.JWT);
					}
				}
			}
			throw Error(`Request failed with status ${response.status} ${response.statusText} for ${method} ${url}`, {
				cause: response,
			});
		}
		return (await response.json()) as T;
	}

	protected async get<T>(url: string, params?: Record<string, unknown>, headers?: HeadersInit) {
		return this.request<T>(url, 'GET', params, headers);
	}

	protected async post<T>(url: string, data?: Record<string, unknown>, headers?: HeadersInit) {
		return this.request<T>(url, 'POST', data, headers);
	}

	protected async put<T>(url: string, data?: Record<string, unknown>, headers?: HeadersInit) {
		return this.request<T>(url, 'PUT', data, headers);
	}

	protected async delete<T>(url: string, data?: Record<string, unknown>, headers?: HeadersInit) {
		return this.request<T>(url, 'DELETE', data, headers);
	}

	protected async patch<T>(url: string, data?: Record<string, unknown>, headers?: HeadersInit) {
		return this.request<T>(url, 'PATCH', data, headers);
	}

	protected async multipartPost<T>(url: string, data: FormData, headers?: HeadersInit) {
		return this.request<T>(url, 'POST', data, {
			...headers,
			'Content-Type': 'multipart/form-data',
		});
	}
}

export default BaseService;
