import { urls } from '@mpx-sdk/shared/configs/urls';
import { type FirebaseCustomTokenResponse } from '@mpx-sdk/types';
import BaseService from './base.service';

class AuthService extends BaseService {
	async getFirebaseCustomToken() {
		return (await this.get<FirebaseCustomTokenResponse>(urls.api.auth.getFirebaseCustomToken)).token;
	}
}

const authService = new AuthService();

export default authService;
