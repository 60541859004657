import { type APIKey, type Developer } from '@api-client/models/platform.model';
import BaseService from '@api-client/services/base.service';

class PlatformService extends BaseService {
	async createDeveloperApp(appName: string): Promise<APIKey> {
		return this.post('/platform/developer/key', { description: appName });
	}

	async listDeveloperApps(): Promise<Developer> {
		return this.get('/platform/developer');
	}

	async getDeveloperApp(appId: string): Promise<APIKey> {
		return this.get(`/platform/developer/keys/${appId}`);
	}

	async deleteDeveloperApp(appId: string): Promise<{ success: boolean }> {
		return this.delete(`/platform/developer/keys/${appId}`);
	}
}

const platformService = new PlatformService();

export default platformService;
