import { assetsService } from '@api-client/index';
import { type PublicAsset, type UserProfile } from '@mpx-sdk/types';
import { isEmpty } from 'lodash';
import { request } from '../api';

export async function getUserData(username: string, options?: { limit: number }) {
	const data = await assetsService.getProjectsByUser(username, options?.limit);
	return data?.[0];
}

// Following related to getting cloud/private projects
export async function updateCloudDownloadInfo(originalProjectData: PublicAsset) {
	const projectData: PublicAsset = { ...originalProjectData };

	if (projectData?.id) {
		const downloadLink = await request(`/my-space/download/${projectData.id}`, 'GET');

		if (downloadLink?.data?.glb?.url) {
			// eslint-disable-next-line no-restricted-syntax
			for (const i in projectData.files) {
				if (projectData.files[i].name?.endsWith('.glb')) {
					projectData.files[i].downloadUrl = downloadLink.data.glb.url;
					break;
				}
			}
		}
	}

	return projectData;
}

export async function getCloudPrivateAssetDownloadInfo(data: any[]) {
	const downloadData = Array.isArray(data) ? [...data] : [];

	await Promise.all(
		downloadData.map(async (project) => {
			if (project && project.id) {
				const downloadLink = await request(`/my-space/download/${project.id}`, 'GET');

				if (downloadLink?.data) {
					project.files = [];

					// eslint-disable-next-line no-restricted-syntax
					for (const [key, archive] of Object.entries<{
						name: string;
						url: string;
						size: number;
						filePath: string;
					}>(downloadLink?.data)) {
						const files: Partial<any> = {};

						if (key === 'glb') {
							if (project.metadata) {
								files.metadata = project?.metadata;
							}
						}

						if (archive?.size) {
							files.size = archive?.size;
						}

						if (archive?.filePath) {
							// This is the file name
							files.name = archive?.filePath.split('/').pop();
						}

						if (archive?.url) {
							files.downloadUrl = archive?.url;
						}

						// If files not empty, add to project files
						if (Object.keys(files).length > 0) {
							project.files.push(files);
						}
					}

					if (project.thumbnailUrl) {
						const files: Partial<any> = {};

						files.name = project?.thumbnailUrl.split('?')[0].split('/').pop();

						files.downloadUrl = project?.thumbnailUrl;

						project.files.push(files);

						delete project.thumbnailUrl;
					}
				}
			}
		}),
	);

	return downloadData;
}

export async function processCloudPrivateDataToMPSProjects(
	data: any,
	currentUser: Partial<UserProfile>,
	getDownloadLinksNow = true,
) {
	let processData = [...data];

	// First convert to appropriate format
	processData.forEach(async (project) => {
		if (!isEmpty(project)) {
			// Delete archives and metadata and thumbnailUrl from project
			delete project?.archives;
			delete project?.metadata;

			if (currentUser) {
				if (!project.user) {
					project.user = {};
				}

				project.user = {
					id: currentUser.id,
					photoUrl: currentUser?.photoUrl,
					username: currentUser?.username,
					name: currentUser?.useName
						? `${currentUser?.firstName} ${currentUser?.lastName}`
						: currentUser?.username || currentUser?.id,
				};
			}

			// Trim project title
			if (project?.title) {
				project.title = project.title.trim();
			}
		}
	});

	if (getDownloadLinksNow) {
		processData = await getCloudPrivateAssetDownloadInfo(processData);
	}

	return processData;
}

export async function getCloudPrivateProjectData(
	currentUser: UserProfile,
	optionalData: { limit?: any; offset?: number; sort?: string },
	convertToMPSProjectFormat = true,
	getDownloadLinksNow = true,
) {
	let data: any = await request('/my-space/assets', 'GET', optionalData || {});

	if (data?.data) {
		data = data.data;

		if (convertToMPSProjectFormat && currentUser) {
			data = processCloudPrivateDataToMPSProjects(data, currentUser, getDownloadLinksNow);
		}

		return data;
	}

	return null;
}
