import { urls } from '@mpx-sdk/shared/configs/urls';
import { type DownloadGeneratedModelType } from '@mpx-sdk/types';
import BaseService from './base.service';

class GenAIService extends BaseService {
	async generate(data: any): Promise<{
		requestId: string;
		reference: string;
	}> {
		return this.post(urls.api.genai.generate, data);
	}

	async generateWithAI(data: { modelToGenerate: number; prompt: string }): Promise<{
		requestId: string;
		reference: string;
	}> {
		return this.post(urls.api.genai.generateWithAI, data);
	}

	async getAccess() {
		return this.get(urls.api.genai.access);
	}

	async addAccess(data: any) {
		return this.patch(urls.api.genai.access, data);
	}

	async testPrompt(data: any) {
		return this.post(urls.api.genai.testPrompt, data);
	}

	async deleteAccess(data: any) {
		return this.delete(urls.api.genai.access, data);
	}

	async downloadGeneratedModel(modelId: string): Promise<DownloadGeneratedModelType> {
		return this.get<DownloadGeneratedModelType>(urls.api.genai.downloadGeneratedModel(modelId));
	}
}

const genAIService = new GenAIService();

export default genAIService;
